import axios from 'axios';

/**
 * ApiConnector helper
 */
export class ApiConnector {
    /**
     * API endpoint to use
     * @type {string}
     */
    endpoint = '//salesmeeting.mykita.loc/api.php';

    /**
     * Axios instance
     * @type {AxiosInstance|null}
     */
    ajax = null;

    /**
     * Default constructor
     */
    constructor() {
        if (process.env.NODE_ENV === 'production') {
            this.endpoint = '/api.php';
        }

        this.ajax = axios.create({
            headers: {'X-Requested-With': 'XMLHttpRequest'},
        });
    }

    /**
     * Login promise
     * @param {string} password The password to use
     * @return {Promise<unknown>} The promise
     */
    login(password) {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = await this.ajax.post(this.endpoint, `password=${password}`);

                if (data.success) {
                    resolve({success: true, content: data.content});
                } else {
                    reject({success: false, message: data.message});
                }
            } catch (error) {
                reject({success: false, error: error});
            }
        });
    }
}