<template>
    <li class="box">
        <a :href="content.link"
           target="_blank"
           rel="noreferrer"
           class="box__link"
           ref="link"
           @click.prevent="updateVimeoUrl">

            <template v-if="content.image">
                <div class="box__image">
                    <RespImage :src="content.image" sizes="24rem" :alt="content.caption"></RespImage>
                </div>
            </template>
            <template v-else>
                <div class="box__fond">
                    <span class="box__fond-text box__fond-text--m" v-text="content.caption"></span>
                </div>
            </template>

            <div class="box__content">
                <span v-if="content.time && content.image" v-text="content.time" class="time"></span>
                <span v-text="content.linkTitle"
                      class="link"></span>
            </div>
        </a>
    </li>
</template>

<script>
import RespImage from '@/components/resp-image.vue';
export default {
    name: 'ListVideoEntry',
    props: {
        content: {
            type: Object,
        }
    },
    components: {
        RespImage,
    },
    methods: {
        updateVimeoUrl() {
            this.$store.commit('updateVimeoUrl', 'about:blank');

            setTimeout(() => {
                this.$store.commit('updateVimeoUrl', this.$refs.link.getAttribute('href'));
            }, 100);
        },
    },
}
</script>

<style scoped lang="scss">
.time {
    margin-right: 0.5em;
}
</style>