<template>
    <div id="app" class="app">
        <header class="header">
            <div class="header__logo"><Logo/></div>
        </header>

        <Login><template v-slot:logo><Logo/></template></Login>

        <main v-if="isLoggedIn" class="main">
            <Vimeo/>
            <Introduction/>
            <TimeTable v-if="false" />
            <Strategy v-if="false" />
            <Recordings v-if="true" />
            <Downloads v-if="true" />
            <Downloads v-if="false" source="mentiMoments"/>
        </main>
    </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';
import Logo from '@/components/logo.vue';
import Login from '@/components/login.vue';
import Vimeo from '@/components/vimeo.vue';
import Introduction from '@/components/introduction.vue';
import TimeTable from '@/components/time-table.vue';
import Strategy from '@/components/strategy.vue';
import Recordings from '@/components/recordings.vue';
import Downloads from '@/components/downloads.vue';

export default {
    name: 'Index',
    components: {
        Logo,
        Login,
        Vimeo,
        Introduction,
        TimeTable,
        Strategy,
        Recordings,
        Downloads,
    },
    computed: {
        isLoggedIn() {
            return this.$store.state.hasContent;
        },
    },
    watch: {
        isLoggedIn(newVal) {
            setTimeout(() => {
                document.querySelector('body').classList.toggle('is-logged-in', newVal);
            }, 200);
        },
    },
    async created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.handleScroll();
        smoothscroll.polyfill();
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            const scrollTop = document.documentElement.scrollTop;
            document.querySelector('body').classList.toggle('has-scrolled', scrollTop > 0);
        }
    }
}
</script>

<style lang="scss">
@import './src/scss/main.scss';
</style>
