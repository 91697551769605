<template>
    <section v-if="isVisible" class="container container--small">
        <div class="countdown">
            <div class="countdown__cell">
                <p class="countdown__value" v-text="days"></p>
                <p class="countdown__label">
                    Days
                </p>
            </div>
            <div class="countdown__cell">
                <p class="countdown__value" v-text="hours"></p>
                <p class="countdown__label">
                    Hours
                </p>
            </div>
            <div class="countdown__cell">
                <p class="countdown__value" v-text="minutes"></p>
                <p class="countdown__label">
                    Minutes
                </p>
            </div>
            <div class="countdown__cell">
                <p class="countdown__value" v-text="seconds"></p>
                <p class="countdown__label">
                    Seconds
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import {ApiConnector} from '@/js/common';

export default {
    name: 'Countdown',
    data() {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            interval: null,
        };
    },
    computed: {
        isVisible() {
            return this.content && this.content.isVisible;
        },
        content() {
            return this.$store.state.countdown;
        }
    },
    methods: {
        getEndDate() {
            if (!this.content.date) {
                return new Date();
            }

            const endTokens = this.content.date.split(' ');
            const dateTokens = endTokens[0].split('-');
            const timeTokens = endTokens[1].split(':');

            return new Date(
                dateTokens[0],
                dateTokens[1] - 1,
                dateTokens[2],
                timeTokens[0],
                timeTokens[1],
                timeTokens[2]
            );
        },
        pad(num, size = 2) {
            let s = num + '';
            while (s.length < (size || 2)) {
                s = '0' + s;
            }

            return s;
        },
        update() {
            let delta = (this.getEndDate().getTime() - Date.now()) / 1000;

            // Refresh if delta is less than 0
            if (delta < 0 && this.isVisible) {
                window.clearInterval(this.interval);
                window.location.reload();

                return;
            }

            const days = Math.floor(delta / 86400);
            delta -= days * 86400;

            const hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            const minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            const seconds = Math.floor(delta % 60);

            this.days = this.pad(days);
            this.hours = this.pad(hours);
            this.minutes = this.pad(minutes);
            this.seconds = this.pad(seconds);

        },
    },
    mounted() {
        this.interval = window.setInterval(this.update.bind(this), 1000);
        this.update();
    },
    unmounted() {
        window.clearInterval(this.interval);
    },
}
</script>