import {createStore} from 'vuex'

export default createStore({
    state: {
        hasContent: false,
        currentVimeoUrl: '',
        vimeo: {showVideo: false},
        introduction: [],
        timeTableHints: [],
        timeTable: [],
        strategy: {},
        recordings: {},
        downloads: {},
        mentiMoments: {},
    },
    mutations: {
        setContent(state, payload) {
            if (payload.introduction) {
                state.hasContent = true;

                const showVideo = state.vimeo.showVideo;

                state.vimeo = payload.vimeo;
                state.introduction = payload.introduction;
                state.timeTable = payload.timeTable;
                state.timeTableHints = payload.timeTableHints;
                state.strategy = payload.strategy;
                state.recordings = payload.recordings;
                state.downloads = payload.downloads;
                state.mentiMoments = payload.mentiMoments;

                // Set vimeo URL if empty
                if (state.currentVimeoUrl.length === 0) {
                    state.currentVimeoUrl = payload.vimeo.url;
                }

                // Make sure video keeps playing upon refresh
                if (showVideo) {
                    state.vimeo.showVideo = true;
                }
            }
        },
        updateVimeoUrl(state, payload) {
            if (payload === 'about:blank') {
                state.vimeo.showVideo = false;
            } else {
                state.currentVimeoUrl = payload;
                state.vimeo.showVideo = true;

                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    },
});
