<template>
    <section class="container" id="video-container">
        <template v-if="showVideo">
            <div class="vimeo">
                <div class="vimeo__wrapper">
                    <iframe :src="url"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            webkitallowfullscreen
                            mozallowfullscreen
                            allowfullscreen></iframe>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="image-wrapper">
                <div class="image-wrapper__box">
                    <RespImage :src="placeholderImage" sizes="90vw" type="teaser"></RespImage>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import RespImage from '@/components/resp-image.vue';
import Countdown from '@/components/countdown.vue';
export default {
    name: 'Vimeo',
    computed: {
        url() {
            return this.$store.state.currentVimeoUrl;
        },
        showVideo() {
            return this.$store.state.vimeo.showVideo;
        },
        placeholderImage() {
            return this.$store.state.vimeo.placeholder;
        },
    },
    components: {
        RespImage,
    },
}
</script>

<style scoped lang="scss">
    .vimeo__wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .image-wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: 50% 50%;
        }
    }
</style>