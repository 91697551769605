<template>
    <section class="container container--small">
        <div class="introduction">
            <p v-for="line in introduction" v-text="line"></p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Introduction',
    computed: {
        introduction() {
            return this.$store.state.introduction;
        }
    }
}
</script>

<style scoped lang="scss">
p {
    @include type-zurich;
    @include type-zurich--5;

    @include breakpoint(two) {
        @include type-zurich--4;
    }

    @include breakpoint(one) {
        @include type-zurich--3;
    }
}

p + p {
    margin-top: 1em;
}
</style>