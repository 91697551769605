<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 16.85">
        <path fill="#000000" d="M15 0L8.78 14.65 2.2 0H0v16.85h1.45v-15l6.77 15h1.1L15.87 1.5v15.35h1.43V0zm17.4 0l-5 8.12L22.22 0h-1.85l6.16 9.62v7.23H28V9.5L34 0zm14.79 0l-8.36 7.46V0h-1.5v16.85h1.5V8l9 8.85h2L40.49 7.7 49.13 0zm6.59 16.85h1.52V0h-1.52zM58.67 0v1.36h5.38v15.49h1.57V1.36H71V0zm18.79 1.36L81.13 11H73.8zM76.57 0L70 16.85h1.57l1.78-4.61h8.25l1.78 4.61H85L78.42 0z"/>
    </svg>
</template>

<script>
export default {
    name: 'Logo',
}
</script>