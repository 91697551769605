<template>
    <div class="login">
        <form class="login__box" method="post" @submit.prevent="handleForm">
            <div class="login__logo">
                <slot name="logo"></slot>
            </div>
            <div class="form-row" ref="formRowPassword">
                <label for="password" class="form-label">Password</label>
                <input type="password"
                       id="password"
                       name="password"
                       class="form-input"
                       autocomplete="off"
                       autocapitalize="off"
                       ref="password">
            </div>
            <div class="form-row form-row--submit">
                <button type="submit" class="btn">Log in</button>
            </div>
        </form>
    </div>
</template>

<script>
import {ApiConnector} from '@/js/common';
export default {
    name: 'Login',
    data() {
        return {
            interval: null,
        };
    },
    methods: {
        handleForm() {
            this.login(this.$refs.password.value);
        },
        login(password) {
            this.$refs.formRowPassword.classList.remove('has-error');

            const connector = new ApiConnector();
            connector.login(password).then((response) => {
                this.$store.commit('setContent', response.content);
                this.saveSessionStorage(password);
            }).catch((response) => {
                this.$refs.formRowPassword.classList.add('has-error');
                this.clearSessionStorage();
            });
        },
        saveSessionStorage(password) {
            if (!('sessionStorage' in window)) {
                return;
            }

            sessionStorage.setItem('password', password);
        },
        clearSessionStorage() {
            if (!('sessionStorage' in window)) {
                return;
            }

            sessionStorage.clear();
        },
    },
    mounted() {
        if (!('sessionStorage' in window)) {
            return;
        }

        if (window.sessionStorage.getItem('password')) {
            this.login(window.sessionStorage.getItem('password'));
        }
    },
}
</script>