<template>
    <img :srcset="srcset" :sizes="sizes" :alt="alt">
</template>

<script>
export default {
    name: 'RespImage',
    props: {
        src: {
            type: String,
        },
        sizes: {
            type: String,
        },
        alt: {
            type: String,
            'default': '',
        },
        type: {
            type: String,
            'default': 'bigger',
        },
    },
    computed: {
        path() {
            const tokens = this.src.split('/');

            return tokens.slice(0, tokens.length - 1).join('/');
        },
        filename() {
            return this.src.split('/').slice(-1).join('/');
        },
        srcset() {
            let srcset;
            if (this.type === 'teaser') {
                srcset = [
                    {
                        width: 360,
                        token: '/ri-s1/teaser/1x/',
                    }, {
                        width: 720,
                        token: '/ri-s2/teaser/1x/',
                    }, {
                        width: 1080,
                        token: '/ri-s3/teaser/1x/',
                    }, {
                        width: 1440,
                        token: '/ri-s4/teaser/1x/',
                    }, {
                        width: 1800,
                        token: '/ri-s5/teaser/1x/',
                    },
                ];
            } else {
                srcset = [
                    {
                        width: 360,
                        token: '/ri-s1/bigger/1x/',
                    }, {
                        width: 720,
                        token: '/ri-s2/bigger/1x/',
                    }, {
                        width: 1080,
                        token: '/ri-s3/bigger/1x/',
                    },

                ];
            }

            return srcset.map((item) => {
                return `${this.path}${item.token}${this.filename} ${item.width}w`;
            }).join(', ');
        }
    }
}
</script>