<template>
    <section class="container container--small time-table-section">
        <template v-if="content.remote">
            <div class="time-table time-table--remote">
                <div v-for="day in content.entries" class="time-table__column">
                    <h3 v-html="day.caption"></h3>

                    <div class="time-table__table">
                        <div class="time-table__time-zones">
                            <abbr class="time-table__time-zone" title="Central European Time">CET</abbr>
                            <abbr class="time-table__time-zone" title="Hong Kong Time">HKT</abbr>
                            <abbr class="time-table__time-zone" title="Eastern Daylight Time">EDT</abbr>
                        </div>

                        <div v-for="entry in day.entries" class="time-table__entry">
                            <div v-if="entry.type === 'break'" class="time-table__break">
                                <p v-html="entry.title"></p>
                            </div>
                            <div v-else class="time-table__entry-content time-table__entry-content--{{entry.type}}">
                                <p v-html="entry.title"></p>
                                <a v-if="entry.link && entry.type === 'session'"
                                   :href="entry.link"
                                   target="_blank"
                                   rel="noreferrer"
                                   class="link">join session</a>
                                <a v-if="entry.link && entry.type === 'video'"
                                   :href="entry.link"
                                   target="_blank"
                                   rel="noreferrer"
                                   ref="link"
                                   class="link"
                                   @click.prevent="updateVimeoUrl">watch full video</a>

                                <template v-if="entry.links">
                                    <a v-for="link in entry.links"
                                       :href="link.link"
                                       v-text="link.title"
                                       target="_blank"
                                       rel="noreferrer"
                                       class="link"></a>
                                </template>
                            </div>

                            <div v-if="entry.type !== 'break'" class="time-table__entry-times">
                                <time v-text="entry.time.cet" data-title="CET"></time>
                                <time v-text="entry.time.hkt" data-title="HKT"></time>
                                <time v-text="entry.time.edt" data-title="EDT"></time>
                            </div>
                        </div>
                    </div>

                    <p v-if="day.hint" class="time-table__column-hint" v-html="day.hint"></p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="time-table time-table--inhouse">
                <div v-for="day in content.entries" class="time-table__column">
                    <h3 v-html="day.caption"></h3>

                    <div class="time-table__table">
                        <div class="time-table__head">
                            <div class="time-table__head-item time-table__head-item--presenter">
                                <p>Presenter</p>
                            </div>
                        </div>

                        <div v-for="entry in day.entries" :class="[entry.type === 'welcome' ? 'time-table__entry time-table__entry-content--welcome' : 'time-table__entry']">
                            <div v-if="entry.type === 'break'" class="time-table__break">
                                <p v-html="entry.title"></p>
                            </div>
                            <div v-else class="time-table__entry-content">
                                <time v-text="entry.time.cet" class="time-table__entry-time"></time>
                                <p v-html="entry.title" class="time-table__entry-topic"></p>
                                <ul class="time-table__entry-presenter">
                                    <li v-if="entry.presenter"><p>Presenter:</p></li>
                                    <li v-for="presenter in entry.presenter"><p v-text="presenter"></p></li>
                                </ul>
                                <template v-if="entry.location">
                                    <a v-if="entry.locationLink" :href="entry.locationLink" target="_blank" class="time-table__entry-location"><p v-html="entry.location"></p></a>
                                    <p v-else v-html="entry.location" class="time-table__entry-location"></p>
                                </template>
                            </div>
                        </div>
                    </div>

                    <p v-if="day.hint" class="time-table__column-hint" v-html="day.hint"></p>
                </div>
            </div>
        </template>


        <div class="time-table-hints">
            <p v-for="hint in hints" v-html="hint"></p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TimeTable',
    computed: {
        content() {
            console.log({...this.$store.state.timeTable});
            return this.$store.state.timeTable;
        },
        hints() {
            return this.$store.state.timeTableHints;
        }
    },
    methods: {
        updateVimeoUrl() {
            this.$store.commit('updateVimeoUrl', this.$refs.link.getAttribute('href'));
        },
    }
}
</script>