<template>
    <section v-if="isVisible" class="container container--small">
        <div class="highlights">
            <h2 v-text="content.caption"></h2>

            <ul class="highlights__entries box-list">
                <ListVideoEntry v-for="entry in content.entries" :content="entry"></ListVideoEntry>
            </ul>
        </div>
    </section>
</template>

<script>
import ListVideoEntry from '@/components/list-video-entry.vue';
export default {
    name: 'Recordings',
    computed: {
        isVisible() {
            return this.content.isVisible && this.content.entries.length > 0;
        },
        content() {
            return this.$store.state.recordings;
        }
    },
    components: {
        ListVideoEntry,
    }
}
</script>

<style scoped lang="scss">
.highlights__entries {
    margin-top: $margin--m;
}
</style>