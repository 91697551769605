<template>
    <section v-if="isVisible" class="container container--small">
        <div class="downloads">
            <h2 v-text="content.caption"></h2>

            <ul class="downloads__entries box-list">
                <ListIDownloadEntry v-for="entry in content.entries" :content="entry"></ListIDownloadEntry>
            </ul>
        </div>
    </section>
</template>

<script>
import ListIDownloadEntry from '@/components/list-download-entry.vue';
export default {
    name: 'Downloads',
    props: {
        source: {
            type: String,
            'default': 'downloads',
        }
    },
    computed: {
        isVisible() {
            return this.content.isVisible && this.content.entries.length > 0;
        },
        content() {
            return this.$store.state[this.source];
        }
    },
    components: {
        ListIDownloadEntry,
    }
}
</script>

<style scoped lang="scss">
.downloads__entries {
    margin-top: $margin--m;
}
</style>