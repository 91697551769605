<template>
    <li class="box">
        <a :href="content.link"
           :target="[shouldDownload ? '_self' : '_blank']"
           rel="noreferrer"
           class="box__link">
            <div class="box__image">
                <RespImage :src="content.image" sizes="24rem" :alt="content.caption"></RespImage>
            </div>

            <div class="box__content">
                <p v-text="content.caption"></p>
                <span v-text="content.linkTitle"
                      class="link"></span>
            </div>
        </a>
    </li>
</template>

<script>
import RespImage from '@/components/resp-image.vue';
export default {
    name: 'ListIDownloadEntry',
    props: {
        content: {
            type: Object,
        }
    },
    components: {
        RespImage,
    },
    computed: {
        shouldDownload() {
            return this.content.link.match(/\.pdf$/i) === null;
        }
    },
}
</script>